import React from "react";
import Header from '../Header/Header'
import Breadcrumbs from '../Footer/Breadcrumb/Breadcrumb'
import Footer from '../Footer/Footer'
import GuideBanner from '../GuideDetails/GuideBanner/GuideBanner'
import GuideDetails from '../GuideDetails/GuideDetails'
import FeaturedProperties from '../GuideDetails/FeaturedProperties/FeaturedProperties'
import Map from '../map/office-details-map'
import _ from 'lodash'

const GuideDetail = (props) => {
    const [state, setState] = React.useState({
        showMenu: false
    })

    const handlerClick = () => {
        setState({ ...state, showMenu: !state.showMenu })
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
    }
    return (
        <>
            <div className={state.showMenu ? "wrapper open-search-block" : "wraaper"}>
                <Header showMenu={state.showMenu} handlerClick={handlerClick} />
                <GuideBanner {...props}/>
                <GuideDetails {...props}/>
                { props.data.Latitude && props.data.Longitude &&
                    <Map lat={props.data.Latitude ? parseFloat(props.data.Latitude) : ''} log={props.data.Longitude ? parseFloat(props.data.Longitude) : ''} />                    
                } 
                <FeaturedProperties Title={`FEATURED PROPERTIES IN ${_.upperCase(props.data.Title)}`} Sub_Title='A selection of our latest instructions' Title_With_Collection={`Featured_Properties`} />
                <Breadcrumbs tag="details" category="About"  subCategory="Area Guide" data={[]} page={props.data.Area_Title}/>
                <Footer popularSearchType='areadetails' title={props.data.Title}/>
            </div>
        </>
    )
}

export default GuideDetail;